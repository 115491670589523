<template>
  <Header headerClass="transparent"></Header>
  <div class="wrapper">
    <Form @submit="submit" :validation-schema="schema" :initial-values="initialValues">
      <FormWrapper>
        <template #form-head>
          <h2>Add a new studio role</h2>
        </template>
        <div v-for="(item, index) in studioRoles" :key="item">
          <TextInput
            placeholderText="Studio role"
            label="Studio role"
            :name="`studioRoles[${index}].name`"
            :type="'text'"
          />
          <TextInput
            placeholderText="Standard hourly rate"
            label="Standard hourly rate"
            :name="`studioRoles[${index}].rate`"
            :type="'number'"
          />
          <TextInput
            placeholderText="Annual salary + benefits"
            label="Annual salary + benefits"
            :name="`studioRoles[${index}].medianFullTimeSalary`"
            :type="'number'"
          />
        </div>
        <CustomButton class="half-button" @onClick="addRoles" iconClass="plus" label="Add another role" type="button" />
        <template #footer>
          <CustomButton color="grey" :iconVisible="false" @onClick="$router.go(-1)" label="Cancel" />
          <CustomButton type="submit" label="save role" color="blue" />
        </template>
      </FormWrapper>
    </Form>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import { useRouter } from 'vue-router'
import authApi from '@/api/Authenticated'
import * as yup from 'yup'
import TextInput from '@/components/atom/TextInput.vue'
import { currencyToInt } from '@/utils/currencyFunctions'
type studioRolesType = {
  name: string
  rate: number
  medianFullTimeSalary: number
}

export default defineComponent({
  name: 'AddEditRole',
  components: {
    CustomButton,
    Form,
    FormWrapper,
    Header,
    TextInput
  },
  async setup() {
    const router = useRouter()
    const schema = yup.object({
      studioRoles: yup.array().of(
        yup.object({
          name: yup.string().required().label('Studio role'),
          rate: yup
            .number()
            .min(0)
            .required()
            .label('Standard hourly rate')
            .typeError('Standard hourly rate must be written as a whole number'),
          medianFullTimeSalary: yup
            .number()
            .min(0)
            .required()
            .label('Annual salary + benefits')
            .typeError('Typical annual salary + benefits must be written as a whole number')
        })
      )
    })

    const initialValues = {
      studioRoles: [
        {
          name: '',
          rate: '',
          medianFullTimeSalary: ''
        }
      ]
    }

    const studioRoles = ref([initialValues])

    const addRoles = () => {
      studioRoles.value.push({
        studioRoles: [
          {
            name: '',
            rate: '',
            medianFullTimeSalary: ''
          }
        ]
      })
    }
    const removeRoles = (index: number) => {
      const filteredArr = studioRoles.value.filter((element, i: number) => {
        return i !== index
      })
      studioRoles.value = [...filteredArr]
    }

    async function submit(values: { studioRoles: studioRolesType[] }) {
      const reqBody = values.studioRoles.map((item) => {
        return {
          ...item,
          medianFullTimeSalary: currencyToInt(+item.medianFullTimeSalary),
          rate: currencyToInt(+item.rate)
        }
      })
      const res = await authApi.addNewProjectRole(reqBody)
      if (res) {
        router.push({ name: 'Roles' })
      }
    }

    return { initialValues, schema, submit, studioRoles, addRoles, removeRoles }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.wrapper
  padding-bottom: 90px
  display: flex
  width: 100%
  margin: 0 auto
  justify-content: center
  .form-group
    margin-top: 1rem
</style>
