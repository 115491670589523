<template>
  <Header headerClass="transparent"></Header>
  <div class="wrapper">
    <Form @submit="submit" :validation-schema="schema" :initial-values="initialValues">
      <FormWrapper>
        <template #form-head>
          <h2>Edit studio role</h2>
        </template>
        <TextInput name="name" type="text" placeholderText="Studio role" label="Studio role" />
        <TextInput name="rate" type="text" placeholderText="Standard hourly rate" label="Standard hourly rate" />
        <TextInput
          name="medianFullTimeSalary"
          type="text"
          placeholderText="Annual salary + benefits"
          label="Typical annual salary + benefits"
        />
        <template #footer>
          <CustomButton color="grey" :iconVisible="false" @onClick="$router.go(-1)" label="Cancel" />
          <CustomButton type="submit" label="Save role" color="blue" />
        </template>
      </FormWrapper>
    </Form>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import { serviceTypes } from '@shared/'
import { useRouter, useRoute } from 'vue-router'
import authApi from '@/api/Authenticated'
import * as yup from 'yup'
import { currencyToInt, inputFieldFormat } from '@/utils/currencyFunctions'
import { decimalRound } from '@/utils/commonFunctions'

export default defineComponent({
  name: 'AddEditRole',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    Header
  },
  async setup() {
    const router = useRouter()
    const route = useRoute()
    const schema = yup.object({
      name: yup.string().required().label('Studio role'),
      rate: yup.number().min(0).required().typeError('Standard hourly rate must be written as a whole number'),
      medianFullTimeSalary: yup
        .number()
        .min(0)
        .required()
        .typeError('Typical annual salary + benefits must be written as a whole number')
    })

    const initialValues = ref<serviceTypes.ServiceResponse>(await authApi.getProjectRole(+route.params.id))
    initialValues.value.rate = inputFieldFormat(+initialValues.value.rate)
    initialValues.value.medianFullTimeSalary = inputFieldFormat(+initialValues.value.medianFullTimeSalary)

    async function submit(values: serviceTypes.ServiceBody) {
      const reqBody = { ...values }
      reqBody.rate = currencyToInt(decimalRound(reqBody.rate))
      reqBody.medianFullTimeSalary = currencyToInt(decimalRound(reqBody.medianFullTimeSalary))
      const res = await authApi.updateProjectRole(reqBody, +route.params.id)
      if (res) {
        router.push({ name: 'Roles' })
      }
    }

    return { initialValues, schema, submit }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.wrapper
  padding-bottom: 90px
  display: flex
  width: 100%
  margin: 0 auto
  justify-content: center
</style>
