
import { ref, defineComponent } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import { serviceTypes } from '@shared/'
import { useRouter, useRoute } from 'vue-router'
import authApi from '@/api/Authenticated'
import * as yup from 'yup'
import { currencyToInt, inputFieldFormat } from '@/utils/currencyFunctions'
import { decimalRound } from '@/utils/commonFunctions'

export default defineComponent({
  name: 'AddEditRole',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    Header
  },
  async setup() {
    const router = useRouter()
    const route = useRoute()
    const schema = yup.object({
      name: yup.string().required().label('Studio role'),
      rate: yup.number().min(0).required().typeError('Standard hourly rate must be written as a whole number'),
      medianFullTimeSalary: yup
        .number()
        .min(0)
        .required()
        .typeError('Typical annual salary + benefits must be written as a whole number')
    })

    const initialValues = ref<serviceTypes.ServiceResponse>(await authApi.getProjectRole(+route.params.id))
    initialValues.value.rate = inputFieldFormat(+initialValues.value.rate)
    initialValues.value.medianFullTimeSalary = inputFieldFormat(+initialValues.value.medianFullTimeSalary)

    async function submit(values: serviceTypes.ServiceBody) {
      const reqBody = { ...values }
      reqBody.rate = currencyToInt(decimalRound(reqBody.rate))
      reqBody.medianFullTimeSalary = currencyToInt(decimalRound(reqBody.medianFullTimeSalary))
      const res = await authApi.updateProjectRole(reqBody, +route.params.id)
      if (res) {
        router.push({ name: 'Roles' })
      }
    }

    return { initialValues, schema, submit }
  }
})
